import { z } from 'zod';

export const scheduleTrackType = z.object({
  description: z.string().nullable(),
  id: z.string().nullable(),
  name: z.string().nullable(),
});

export const timeslotChannelType = z.object({
  id: z.string(),
  name: z.string(),
  slug: z.string(),
});

export const formatType = z.object({
  label: z.string(),
});

export const locationType = z.object({
  name: z.string(),
});

export const timeslotParticipationSchema = z.object({
  avatar_urls: z.object({
    tiny: z.string().nullish(),
  }),
  company_name: z.string().nullable(),
  id: z.string(),
  job_title: z.string().nullable(),
  name: z.string(),
});

export const timeslotHitSchema = z.object({
  calendar_event_id: z.string().nullable(),
  channel: timeslotChannelType.nullable(),
  duration_mins: z.number(),
  ends_at: z.string(),
  format: formatType.nullish(),
  has_video_archive: z.boolean(),
  id: z.string().nonempty(),
  location: locationType.nullish(),
  online_session: z.boolean(),
  premium: z.boolean().optional(),
  schedule_track: scheduleTrackType.nullable(),
  starts_at: z.string(),
  timeslot_participations: z.array(timeslotParticipationSchema),
  title: z.string(),
});

export const exibitorHitSchema = z.object({
  id: z.string(),
  industry: z.string(),
  logo_urls: z.object({
    original: z.string(),
    medium: z.string(),
    thumb: z.string(),
  }),
  name: z.string(),
  currently_recruiting: z.boolean(),
  fundraising: z.boolean(),
  selected_for_pitch: z.boolean(),
  city: z.string().nullable(),
  country: z.string().nullable(),
  elevator_pitch: z.string().nullable(),
  funding_stage: z.string().nullable(),
  funding_tier: z.string().nullable(),
  customer_presence: z.array(z.string()),
  external_urls: z.object({
    facebook: z.string().nullable(),
    twitter: z.string().nullable(),
    crunchbase: z.string().nullable(),
    angellist: z.string().nullable(),
    linkedin: z.string().nullable(),
  }),
  track: z.string(),
  revenue: z.string().nullable(),
  has_pitch_deck: z.boolean(),
  startup_women_founder: z.boolean().nullable().optional(),
  startup_black_founder: z.boolean().nullable().optional(),
  startup_indigenous_founder: z.boolean().nullable().optional(),
});

export const attendeeHitSchema = z.object({
  avatar_urls: z.object({
    thumb: z.string(),
  }),
  company_name: z.string(),
  first_name: z.string(),
  id: z.string().nonempty(),
  job_title: z.string(),
  last_name: z.string(),
  role: z.string(),
});

export const timeslotHitListSchema = z.array(timeslotHitSchema);
export const exibitorHitListSchema = z.array(exibitorHitSchema);
export const attendeeHitListSchema = z.array(attendeeHitSchema);

export type Participation = z.infer<typeof timeslotParticipationSchema>;
export type TimeslotHitType = z.infer<typeof timeslotHitSchema>;
export type TimeslotHitListType = z.infer<typeof timeslotHitListSchema>;
export type ExibitorHitType = z.infer<typeof exibitorHitSchema>;
export type ExibitorHitListType = z.infer<typeof exibitorHitListSchema>;
export type AttendeeHitType = z.infer<typeof attendeeHitSchema>;
export type AttendeeHitListType = z.infer<typeof attendeeHitListSchema>;

export enum SearchPageVariant {
  Attendees = 'attendees',
  Partners = 'partners',
  Startups = 'startups',
}

export type SearchConfig = {
  appId: string;
  facetFilters?: string[];
  facets?: string[];
  filters?: string;
  hitsPerPage?: number;
  replicaName: string;
  searchAPIKey: string;
};

export const hostSchema = z.object({
  id: z.string(),
  image_url: z.string(),
  name: z.string(),
});

export enum RoomType {
  Community = 'community',
  Partner = 'partner',
}

export const roomHitSchema = z.object({
  capacity: z.number(),
  closed_at: z.string(),
  description: z.string(),
  hosts: z.array(hostSchema),
  id: z.string(),
  name: z.string(),
  type: z.nativeEnum(RoomType),
});

export type Host = z.infer<typeof hostSchema>;
export type RoomHit = z.infer<typeof roomHitSchema>;
